import { openDB } from 'idb';

const dbPromise = openDB('image-store', 1, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('images')) {
            db.createObjectStore('images');
        }
    },
});

export async function setCachedImage(url, blob) {
    const db = await dbPromise;
    const tx = db.transaction('images', 'readwrite');
    tx.store.put(blob, url);
    await tx.done;
}

export async function getCachedImage(url) {
    const db = await dbPromise;
    return db.transaction('images').store.get(url);
}

export async function getAllCachedImages() {
    const db = await dbPromise;
    return db.transaction('images').store.getAll();
}

export async function clearCachedImages() {
    const db = await dbPromise;
    const tx = db.transaction('images', 'readwrite');
    await tx.store.clear();
    await tx.done;
}
