import { setCachedImage } from './imageCache';

export async function preloadImages(imageUrls) {
    const preloadPromises = imageUrls.map(async (url) => {
        try {
            // Проверяем наличие изображения в кэше
            const response = await fetch(url);
            const blob = await response.blob();
            await setCachedImage(url, blob); // Сохраняем изображение в IndexedDB
        } catch (error) {
            console.error(`Ошибка при загрузке изображения ${url}:`, error);
        }
    });

    await Promise.all(preloadPromises); // Дожидаемся завершения всех загрузок
}
