import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.css'; // Ensure this is imported for styling

const Menu = () => {

  const handleCardsClick = (event) => {
    event.preventDefault(); // Предотвращает переход по ссылке
    alert('Upgrade will appear soon');
  };

  const handleTasksClick = (event) => {
    event.preventDefault(); // Предотвращает переход по ссылке
    alert('Tasks will appear soon');
  };

  return (
    <div className="menu">
      <NavLink to="/" end className={({ isActive }) => (isActive ? 'active' : '')}>
        {({ isActive }) => (
          <>
            {isActive ? (
              <div className="svg-container">
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
              <g filter="url(#filter0_f_34_1279)">
              <path fillRule="evenodd" clipRule="evenodd" d="M20.8141 27.1265C20.2083 28.2331 20.2083 29.5681 20.2083 32.2379V34.0124C20.2083 38.5635 20.2083 40.839 21.5752 42.2528C22.942 43.6666 25.1419 43.6666 29.5417 43.6666H34.2083C38.6081 43.6666 40.808 43.6666 42.1748 42.2528C43.5417 40.839 43.5417 38.5635 43.5417 34.0124V32.2379C43.5417 29.5681 43.5417 28.2331 42.9359 27.1265C42.3302 26.0199 41.2236 25.3331 39.0103 23.9595L36.677 22.5113C34.3374 21.0593 33.1676 20.3333 31.875 20.3333C30.5824 20.3333 29.4126 21.0593 27.073 22.5113L24.7397 23.9595C22.5264 25.3331 21.4198 26.0199 20.8141 27.1265ZM28.375 38.125C27.8918 38.125 27.5 38.5167 27.5 39C27.5 39.4832 27.8918 39.875 28.375 39.875H35.375C35.8583 39.875 36.25 39.4832 36.25 39C36.25 38.5167 35.8583 38.125 35.375 38.125H28.375Z" fill="url(#paint0_linear_34_1279)" fillOpacity="0.6"/>
              </g>
              <g filter="url(#filter1_f_34_1279)">
              <path fillRule="evenodd" clipRule="evenodd" d="M20.8141 27.1265C20.2083 28.2331 20.2083 29.5681 20.2083 32.2379V34.0124C20.2083 38.5635 20.2083 40.839 21.5752 42.2528C22.942 43.6666 25.1419 43.6666 29.5417 43.6666H34.2083C38.6081 43.6666 40.808 43.6666 42.1748 42.2528C43.5417 40.839 43.5417 38.5635 43.5417 34.0124V32.2379C43.5417 29.5681 43.5417 28.2331 42.9359 27.1265C42.3302 26.0199 41.2236 25.3331 39.0103 23.9595L36.677 22.5113C34.3374 21.0593 33.1676 20.3333 31.875 20.3333C30.5824 20.3333 29.4126 21.0593 27.073 22.5113L24.7397 23.9595C22.5264 25.3331 21.4198 26.0199 20.8141 27.1265ZM28.375 38.125C27.8918 38.125 27.5 38.5167 27.5 39C27.5 39.4832 27.8918 39.875 28.375 39.875H35.375C35.8583 39.875 36.25 39.4832 36.25 39C36.25 38.5167 35.8583 38.125 35.375 38.125H28.375Z" fill="url(#paint1_linear_34_1279)"/>
              </g>
              <path fillRule="evenodd" clipRule="evenodd" d="M20.8141 27.1265C20.2083 28.2332 20.2083 29.5681 20.2083 32.2379V34.0125C20.2083 38.5635 20.2083 40.839 21.5752 42.2528C22.942 43.6667 25.1419 43.6667 29.5417 43.6667H34.2083C38.6081 43.6667 40.808 43.6667 42.1748 42.2528C43.5417 40.839 43.5417 38.5635 43.5417 34.0125V32.2379C43.5417 29.5681 43.5417 28.2332 42.9359 27.1265C42.3302 26.0199 41.2236 25.3331 39.0103 23.9595L36.677 22.5114C34.3374 21.0593 33.1676 20.3333 31.875 20.3333C30.5824 20.3333 29.4126 21.0593 27.073 22.5114L24.7397 23.9595C22.5264 25.3331 21.4198 26.0199 20.8141 27.1265ZM28.375 38.125C27.8918 38.125 27.5 38.5168 27.5 39C27.5 39.4833 27.8918 39.875 28.375 39.875H35.375C35.8583 39.875 36.25 39.4833 36.25 39C36.25 38.5168 35.8583 38.125 35.375 38.125H28.375Z" fill="url(#paint2_linear_34_1279)"/>
              <defs>
              <filter id="filter0_f_34_1279" x="0.208344" y="0.333313" width="63.3333" height="63.3333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_34_1279"/>
              </filter>
              <filter id="filter1_f_34_1279" x="16.2083" y="16.3333" width="31.3333" height="31.3333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_34_1279"/>
              </filter>
              <linearGradient id="paint0_linear_34_1279" x1="31.875" y1="20.3333" x2="31.875" y2="43.6666" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2FB8FF"/>
              <stop offset="1" stopColor="#9EECD9"/>
              </linearGradient>
              <linearGradient id="paint1_linear_34_1279" x1="31.875" y1="20.3333" x2="31.875" y2="43.6666" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2FB8FF"/>
              <stop offset="1" stopColor="#9EECD9"/>
              </linearGradient>
              <linearGradient id="paint2_linear_34_1279" x1="31.875" y1="20.3333" x2="31.875" y2="43.6667" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2FB8FF"/>
              <stop offset="1" stopColor="#9EECD9"/>
              </linearGradient>
              </defs>
              </svg>
            </div>              
            ) : (
              <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M3.03933 9.12657C2.43359 10.2332 2.43359 11.5681 2.43359 14.2379V16.0125C2.43359 20.5635 2.43359 22.8391 3.80043 24.2529C5.16726 25.6667 7.36715 25.6667 11.7669 25.6667H16.4336C20.8334 25.6667 23.0333 25.6667 24.4001 24.2529C25.7669 22.8391 25.7669 20.5635 25.7669 16.0125V14.2379C25.7669 11.5681 25.7669 10.2332 25.1612 9.12657C24.5555 8.01995 23.4488 7.33314 21.2356 5.95952L18.9022 4.51139C16.5626 3.05938 15.3929 2.33337 14.1003 2.33337C12.8077 2.33337 11.6379 3.05938 9.2983 4.51139L6.96497 5.95952C4.7517 7.33314 3.64506 8.01995 3.03933 9.12657ZM10.6003 20.125C10.117 20.125 9.72526 20.5168 9.72526 21C9.72526 21.4833 10.117 21.875 10.6003 21.875H17.6003C18.0835 21.875 18.4753 21.4833 18.4753 21C18.4753 20.5168 18.0835 20.125 17.6003 20.125H10.6003Z" fill="white" fillOpacity="0.3" />
</svg>
            )}
            Mine
            
          </>
        )}
      </NavLink>
      <NavLink to="/cards" className={({ isActive }) => (isActive ? 'active' : '')} >
        {({ isActive }) => (
          <>
            {isActive ? (
              <div className="svg-container">
              <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_f_122_8594)">
                <path d="M37.9824 20.3333H27.4175C26.0142 20.3333 25.3125 20.3333 24.7487 20.6818C24.1848 21.0303 23.871 21.6579 23.2435 22.913L21.6059 27.0525C21.2276 28.0089 20.8966 29.1361 21.5334 29.9437C21.9607 30.4855 22.623 30.8333 23.3665 30.8333C24.6552 30.8333 25.6999 29.7887 25.6999 28.5C25.6999 29.7887 26.7445 30.8333 28.0332 30.8333C29.3219 30.8333 30.3665 29.7887 30.3665 28.5C30.3665 29.7887 31.4112 30.8333 32.6999 30.8333C33.9885 30.8333 35.0332 29.7887 35.0332 28.5C35.0332 29.7887 36.0779 30.8333 37.3665 30.8333C38.6552 30.8333 39.6999 29.7887 39.6999 28.5C39.6999 29.7887 40.7445 30.8333 42.0332 30.8333C42.7768 30.8333 43.4391 30.4855 43.8664 29.9437C44.5033 29.1361 44.1723 28.0089 43.7939 27.0525L42.1564 22.913C41.5288 21.6579 41.215 21.0303 40.6512 20.6818C40.0873 20.3333 39.3857 20.3333 37.9824 20.3333Z" fill="url(#paint0_linear_122_8594)" fillOpacity="0.6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M42.0332 42.7917H44.3665C44.8498 42.7917 45.2415 43.1834 45.2415 43.6667C45.2415 44.1499 44.8498 44.5417 44.3665 44.5417H21.0332C20.55 44.5417 20.1582 44.1499 20.1582 43.6667C20.1582 43.1834 20.55 42.7917 21.0332 42.7917H23.3665L23.3665 32.5834C24.2341 32.5834 25.0385 32.3128 25.6999 31.8515C26.3613 32.3128 27.1656 32.5834 28.0332 32.5834C28.9008 32.5834 29.7051 32.3128 30.3665 31.8515C31.0279 32.3128 31.8323 32.5834 32.6999 32.5834C33.5674 32.5834 34.3718 32.3128 35.0332 31.8515C35.6946 32.3128 36.499 32.5834 37.3665 32.5834C38.2341 32.5834 39.0385 32.3128 39.6999 31.8515C40.3613 32.3128 41.1656 32.5834 42.0332 32.5834L42.0332 42.7917ZM29.7832 42.7917H35.6165V39.5833C35.6165 38.493 35.6165 37.9478 35.3821 37.5417C35.2285 37.2756 35.0076 37.0547 34.7415 36.9011C34.3354 36.6667 33.7903 36.6667 32.6999 36.6667C31.6095 36.6667 31.0643 36.6667 30.6582 36.9011C30.3922 37.0547 30.1713 37.2756 30.0177 37.5417C29.7832 37.9478 29.7832 38.493 29.7832 39.5833V42.7917Z" fill="url(#paint1_linear_122_8594)" fillOpacity="0.6" />
              </g>
              <g filter="url(#filter1_f_122_8594)">
                <path d="M37.9824 20.3333H27.4175C26.0142 20.3333 25.3125 20.3333 24.7487 20.6818C24.1848 21.0303 23.871 21.6579 23.2435 22.913L21.6059 27.0525C21.2276 28.0089 20.8966 29.1361 21.5334 29.9437C21.9607 30.4855 22.623 30.8333 23.3665 30.8333C24.6552 30.8333 25.6999 29.7887 25.6999 28.5C25.6999 29.7887 26.7445 30.8333 28.0332 30.8333C29.3219 30.8333 30.3665 29.7887 30.3665 28.5C30.3665 29.7887 31.4112 30.8333 32.6999 30.8333C33.9885 30.8333 35.0332 29.7887 35.0332 28.5C35.0332 29.7887 36.0779 30.8333 37.3665 30.8333C38.6552 30.8333 39.6999 29.7887 39.6999 28.5C39.6999 29.7887 40.7445 30.8333 42.0332 30.8333C42.7768 30.8333 43.4391 30.4855 43.8664 29.9437C44.5033 29.1361 44.1723 28.0089 43.7939 27.0525L42.1564 22.913C41.5288 21.6579 41.215 21.0303 40.6512 20.6818C40.0873 20.3333 39.3857 20.3333 37.9824 20.3333Z" fill="url(#paint2_linear_122_8594)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M42.0332 42.7917H44.3665C44.8498 42.7917 45.2415 43.1834 45.2415 43.6667C45.2415 44.1499 44.8498 44.5417 44.3665 44.5417H21.0332C20.55 44.5417 20.1582 44.1499 20.1582 43.6667C20.1582 43.1834 20.55 42.7917 21.0332 42.7917H23.3665L23.3665 32.5834C24.2341 32.5834 25.0385 32.3128 25.6999 31.8515C26.3613 32.3128 27.1656 32.5834 28.0332 32.5834C28.9008 32.5834 29.7051 32.3128 30.3665 31.8515C31.0279 32.3128 31.8323 32.5834 32.6999 32.5834C33.5674 32.5834 34.3718 32.3128 35.0332 31.8515C35.6946 32.3128 36.499 32.5834 37.3665 32.5834C38.2341 32.5834 39.0385 32.3128 39.6999 31.8515C40.3613 32.3128 41.1656 32.5834 42.0332 32.5834L42.0332 42.7917ZM29.7832 42.7917H35.6165V39.5833C35.6165 38.493 35.6165 37.9478 35.3821 37.5417C35.2285 37.2756 35.0076 37.0547 34.7415 36.9011C34.3354 36.6667 33.7903 36.6667 32.6999 36.6667C31.6095 36.6667 31.0643 36.6667 30.6582 36.9011C30.3922 37.0547 30.1713 37.2756 30.0177 37.5417C29.7832 37.9478 29.7832 38.493 29.7832 39.5833V42.7917Z" fill="url(#paint3_linear_122_8594)" />
              </g>
              <path d="M37.9829 20.3333H27.418C26.0147 20.3333 25.313 20.3333 24.7492 20.6818C24.1853 21.0303 23.8715 21.6579 23.244 22.913L21.6064 27.0525C21.2281 28.0089 20.897 29.1361 21.5339 29.9437C21.9612 30.4855 22.6235 30.8333 23.367 30.8333C24.6557 30.8333 25.7004 29.7887 25.7004 28.5C25.7004 29.7887 26.745 30.8333 28.0337 30.8333C29.3224 30.8333 30.367 29.7887 30.367 28.5C30.367 29.7887 31.4117 30.8333 32.7004 30.8333C33.989 30.8333 35.0337 29.7887 35.0337 28.5C35.0337 29.7887 36.0784 30.8333 37.367 30.8333C38.6557 30.8333 39.7004 29.7887 39.7004 28.5C39.7004 29.7887 40.745 30.8333 42.0337 30.8333C42.7773 30.8333 43.4396 30.4855 43.8669 29.9437C44.5038 29.1361 44.1727 28.0089 43.7944 27.0525L42.1569 22.913C41.5293 21.6579 41.2155 21.0303 40.6516 20.6818C40.0878 20.3333 39.3862 20.3333 37.9829 20.3333Z" fill="url(#paint4_linear_122_8594)" />
              <path fillRule="evenodd" clipRule="evenodd" d="M42.0337 42.7917H44.367C44.8503 42.7917 45.242 43.1834 45.242 43.6667C45.242 44.1499 44.8503 44.5417 44.367 44.5417H21.0337C20.5504 44.5417 20.1587 44.1499 20.1587 43.6667C20.1587 43.1834 20.5504 42.7917 21.0337 42.7917H23.367L23.367 32.5834C24.2346 32.5834 25.039 32.3128 25.7004 31.8515C26.3618 32.3128 27.1661 32.5834 28.0337 32.5834C28.9013 32.5834 29.7056 32.3128 30.367 31.8515C31.0284 32.3128 31.8328 32.5834 32.7004 32.5834C33.5679 32.5834 34.3723 32.3128 35.0337 31.8515C35.6951 32.3128 36.4995 32.5834 37.367 32.5834C38.2346 32.5834 39.039 32.3128 39.7004 31.8515C40.3618 32.3128 41.1661 32.5834 42.0337 32.5834L42.0337 42.7917ZM29.7837 42.7917H35.617V39.5833C35.617 38.493 35.617 37.9478 35.3826 37.5417C35.229 37.2756 35.0081 37.0547 34.742 36.9011C34.3359 36.6667 33.7907 36.6667 32.7004 36.6667C31.61 36.6667 31.0648 36.6667 30.6587 36.9011C30.3927 37.0547 30.1717 37.2756 30.0181 37.5417C29.7837 37.9478 29.7837 38.493 29.7837 39.5833V42.7917Z" fill="url(#paint5_linear_122_8594)" />
              <defs>
                <filter id="filter0_f_122_8594" x="0.158203" y="0.333344" width="65.0835" height="64.2083" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_122_8594" />
                </filter>
                <filter id="filter1_f_122_8594" x="16.1582" y="16.3333" width="33.0835" height="32.2083" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_122_8594" />
                </filter>
                <linearGradient id="paint0_linear_122_8594" x1="32.6999" y1="20.3333" x2="32.6999" y2="44.5417" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint1_linear_122_8594" x1="32.6999" y1="20.3333" x2="32.6999" y2="44.5417" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint2_linear_122_8594" x1="32.6999" y1="20.3333" x2="32.6999" y2="44.5417" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint3_linear_122_8594" x1="32.6999" y1="20.3333" x2="32.6999" y2="44.5417" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint4_linear_122_8594" x1="32.7004" y1="20.3333" x2="32.7004" y2="44.5417" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint5_linear_122_8594" x1="32.7004" y1="20.3333" x2="32.7004" y2="44.5417" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
              </defs>
            </svg>
            </div>
            ) : (
              <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.9829 2.33337H9.41795C8.01466 2.33337 7.31302 2.33337 6.74917 2.68186C6.18531 3.03034 5.87153 3.65791 5.24396 4.91304L3.60641 9.05255C3.22807 10.0089 2.89704 11.1362 3.53391 11.9438C3.96116 12.4856 4.62346 12.8334 5.36702 12.8334C6.65569 12.8334 7.70036 11.7887 7.70036 10.5C7.70036 11.7887 8.74503 12.8334 10.0337 12.8334C11.3224 12.8334 12.367 11.7887 12.367 10.5C12.367 11.7887 13.4117 12.8334 14.7004 12.8334C15.989 12.8334 17.0337 11.7887 17.0337 10.5C17.0337 11.7887 18.0784 12.8334 19.367 12.8334C20.6557 12.8334 21.7004 11.7887 21.7004 10.5C21.7004 11.7887 22.745 12.8334 24.0337 12.8334C24.7773 12.8334 25.4396 12.4856 25.8669 11.9438C26.5038 11.1362 26.1727 10.0089 25.7944 9.05254L24.1569 4.91304C23.5293 3.65791 23.2155 3.03034 22.6516 2.68186C22.0878 2.33337 21.3862 2.33337 19.9829 2.33337Z" fill="white" fillOpacity="0.3" />
  <path fillRule="evenodd" clipRule="evenodd" d="M24.0337 24.7917H26.367C26.8503 24.7917 27.242 25.1835 27.242 25.6667C27.242 26.15 26.8503 26.5417 26.367 26.5417H3.03369C2.55044 26.5417 2.15869 26.15 2.15869 25.6667C2.15869 25.1835 2.55044 24.7917 3.03369 24.7917H5.36703L5.36703 14.5834C6.23459 14.5834 7.03896 14.3128 7.70036 13.8515C8.36176 14.3128 9.16613 14.5834 10.0337 14.5834C10.9013 14.5834 11.7056 14.3128 12.367 13.8515C13.0284 14.3128 13.8328 14.5834 14.7004 14.5834C15.5679 14.5834 16.3723 14.3128 17.0337 13.8515C17.6951 14.3128 18.4995 14.5834 19.367 14.5834C20.2346 14.5834 21.039 14.3128 21.7004 13.8515C22.3618 14.3128 23.1661 14.5834 24.0337 14.5834L24.0337 24.7917ZM11.7837 24.7917H17.617V21.5834C17.617 20.493 17.617 19.9478 17.3826 19.5417C17.229 19.2757 17.0081 19.0548 16.742 18.9012C16.3359 18.6667 15.7907 18.6667 14.7004 18.6667C13.61 18.6667 13.0648 18.6667 12.6587 18.9012C12.3927 19.0548 12.1717 19.2757 12.0181 19.5417C11.7837 19.9478 11.7837 20.493 11.7837 21.5834V24.7917Z" fill="white" fillOpacity="0.3" />
</svg>
            )}
            Upgrade
          </>
        )}
      </NavLink>
      <NavLink to="/earn" className={({ isActive }) => (isActive ? 'active' : '')} >
        {({ isActive }) => (
          <>
            {isActive ? (
              <div className="svg-container">
              <svg width="61" height="64" viewBox="0 0 61 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_f_122_10711)">
                <path d="M27.5833 20.3334C26.6168 20.3334 25.8333 21.1169 25.8333 22.0834V23.25C25.8333 24.2165 26.6168 25 27.5833 25H33.4167C34.3832 25 35.1667 24.2165 35.1667 23.25V22.0834C35.1667 21.1169 34.3832 20.3334 33.4167 20.3334H27.5833Z" fill="url(#paint0_linear_122_10711)" fillOpacity="0.6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.0833 22.7094C22.6158 22.7918 21.6893 23.0256 21.0251 23.6898C20 24.7149 20 26.3648 20 29.6647V36.6647C20 39.9645 20 41.6144 21.0251 42.6396C22.0503 43.6647 23.7002 43.6647 27 43.6647H34C37.2998 43.6647 38.9497 43.6647 39.9749 42.6396C41 41.6144 41 39.9645 41 36.6647V29.6647C41 26.3648 41 24.7149 39.9749 23.6898C39.3107 23.0256 38.3842 22.7918 36.9167 22.7094V23.25C36.9167 25.183 35.3497 26.75 33.4167 26.75H27.5833C25.6503 26.75 24.0833 25.183 24.0833 23.25V22.7094ZM34.597 32.6397C34.9503 32.31 34.9694 31.7563 34.6397 31.403C34.3099 31.0497 33.7563 31.0306 33.403 31.3604L29 35.4698L27.597 34.1604C27.2437 33.8306 26.6901 33.8497 26.3603 34.203C26.0306 34.5563 26.0497 35.11 26.403 35.4397L28.403 37.3064C28.7392 37.6202 29.2608 37.6202 29.597 37.3064L34.597 32.6397Z" fill="url(#paint1_linear_122_10711)" fillOpacity="0.6" />
              </g>
              <g filter="url(#filter1_f_122_10711)">
                <path d="M27.5833 20.3334C26.6168 20.3334 25.8333 21.1169 25.8333 22.0834V23.25C25.8333 24.2165 26.6168 25 27.5833 25H33.4167C34.3832 25 35.1667 24.2165 35.1667 23.25V22.0834C35.1667 21.1169 34.3832 20.3334 33.4167 20.3334H27.5833Z" fill="url(#paint2_linear_122_10711)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.0833 22.7094C22.6158 22.7918 21.6893 23.0256 21.0251 23.6898C20 24.7149 20 26.3648 20 29.6647V36.6647C20 39.9645 20 41.6144 21.0251 42.6396C22.0503 43.6647 23.7002 43.6647 27 43.6647H34C37.2998 43.6647 38.9497 43.6647 39.9749 42.6396C41 41.6144 41 39.9645 41 36.6647V29.6647C41 26.3648 41 24.7149 39.9749 23.6898C39.3107 23.0256 38.3842 22.7918 36.9167 22.7094V23.25C36.9167 25.183 35.3497 26.75 33.4167 26.75H27.5833C25.6503 26.75 24.0833 25.183 24.0833 23.25V22.7094ZM34.597 32.6397C34.9503 32.31 34.9694 31.7563 34.6397 31.403C34.3099 31.0497 33.7563 31.0306 33.403 31.3604L29 35.4698L27.597 34.1604C27.2437 33.8306 26.6901 33.8497 26.3603 34.203C26.0306 34.5563 26.0497 35.11 26.403 35.4397L28.403 37.3064C28.7392 37.6202 29.2608 37.6202 29.597 37.3064L34.597 32.6397Z" fill="url(#paint3_linear_122_10711)" />
              </g>
              <path d="M27.5833 20.3334C26.6168 20.3334 25.8333 21.1169 25.8333 22.0834V23.25C25.8333 24.2165 26.6168 25 27.5833 25H33.4167C34.3832 25 35.1667 24.2165 35.1667 23.25V22.0834C35.1667 21.1169 34.3832 20.3334 33.4167 20.3334H27.5833Z" fill="url(#paint4_linear_122_10711)" />
              <path fillRule="evenodd" clipRule="evenodd" d="M24.0833 22.7094C22.6158 22.7918 21.6893 23.0256 21.0251 23.6898C20 24.7149 20 26.3648 20 29.6647V36.6647C20 39.9645 20 41.6144 21.0251 42.6396C22.0503 43.6647 23.7002 43.6647 27 43.6647H34C37.2998 43.6647 38.9497 43.6647 39.9749 42.6396C41 41.6144 41 39.9645 41 36.6647V29.6647C41 26.3648 41 24.7149 39.9749 23.6898C39.3107 23.0256 38.3842 22.7918 36.9167 22.7094V23.25C36.9167 25.183 35.3497 26.75 33.4167 26.75H27.5833C25.6503 26.75 24.0833 25.183 24.0833 23.25V22.7094ZM34.597 32.6397C34.9503 32.31 34.9694 31.7563 34.6397 31.403C34.3099 31.0497 33.7563 31.0306 33.403 31.3604L29 35.4698L27.597 34.1604C27.2437 33.8306 26.6901 33.8497 26.3603 34.203C26.0306 34.5563 26.0497 35.11 26.403 35.4397L28.403 37.3064C28.7392 37.6202 29.2608 37.6202 29.597 37.3064L34.597 32.6397Z" fill="url(#paint5_linear_122_10711)" />
              <defs>
                <filter id="filter0_f_122_10711" x="0" y="0.333374" width="61" height="63.3313" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_122_10711" />
                </filter>
                <filter id="filter1_f_122_10711" x="16" y="16.3334" width="29" height="31.3313" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_122_10711" />
                </filter>
                <linearGradient id="paint0_linear_122_10711" x1="30.5" y1="20.3334" x2="30.5" y2="43.6647" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint1_linear_122_10711" x1="30.5" y1="20.3334" x2="30.5" y2="43.6647" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint2_linear_122_10711" x1="30.5" y1="20.3334" x2="30.5" y2="43.6647" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint3_linear_122_10711" x1="30.5" y1="20.3334" x2="30.5" y2="43.6647" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint4_linear_122_10711" x1="30.5" y1="20.3334" x2="30.5" y2="43.6647" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint5_linear_122_10711" x1="30.5" y1="20.3334" x2="30.5" y2="43.6647" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
              </defs>
            </svg>
            </div>
            ) : (
              <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.5833 2.33337C10.6168 2.33337 9.83333 3.11688 9.83333 4.08337V5.25004C9.83333 6.21654 10.6168 7.00004 11.5833 7.00004H17.4167C18.3832 7.00004 19.1667 6.21654 19.1667 5.25004V4.08337C19.1667 3.11688 18.3832 2.33337 17.4167 2.33337H11.5833Z" fill="white" fillOpacity="0.3" />
  <path fillRule="evenodd" clipRule="evenodd" d="M8.08333 4.70943C6.61578 4.79176 5.68931 5.02562 5.02513 5.68981C4 6.71493 4 8.36485 4 11.6647V18.6647C4 21.9645 4 23.6144 5.02513 24.6396C6.05025 25.6647 7.70017 25.6647 11 25.6647H18C21.2998 25.6647 22.9497 25.6647 23.9749 24.6396C25 23.6144 25 21.9645 25 18.6647V11.6647C25 8.36485 25 6.71493 23.9749 5.68981C23.3107 5.02562 22.3842 4.79176 20.9167 4.70943V5.25004C20.9167 7.18304 19.3497 8.75004 17.4167 8.75004H11.5833C9.65034 8.75004 8.08333 7.18304 8.08333 5.25004V4.70943ZM18.597 14.6397C18.9503 14.31 18.9694 13.7563 18.6397 13.403C18.3099 13.0497 17.7563 13.0306 17.403 13.3604L13 17.4698L11.597 16.1604C11.2437 15.8306 10.6901 15.8497 10.3603 16.203C10.0306 16.5563 10.0497 17.11 10.403 17.4397L12.403 19.3064C12.7392 19.6202 13.2608 19.6202 13.597 19.3064L18.597 14.6397Z" fill="white" fillOpacity="0.3" />
</svg>
            )}
            Tasks
          </>
        )}
      </NavLink>
      <NavLink to="/friends" className={({ isActive }) => (isActive ? 'active' : '')}>
        {({ isActive }) => (
          <>
            {isActive ? (
              <div className="svg-container">
              <svg width="66" height="66" viewBox="0 0 66 66" fill="none">
              <g opacity="0.6" filter="url(#filter0_f_1_163)">
                <path d="M35.2917 25.3333C35.2917 28.2789 32.9039 30.6667 29.9583 30.6667C27.0128 30.6667 24.625 28.2789 24.625 25.3333C24.625 22.3878 27.0128 20 29.9583 20C32.9039 20 35.2917 22.3878 35.2917 25.3333Z" fill="url(#paint0_linear_1_163)" />
                <path d="M39.2917 40.0013C39.2917 42.9468 35.113 45.3346 29.9583 45.3346C24.8037 45.3346 20.625 42.9468 20.625 40.0013C20.625 37.0558 24.8037 34.668 29.9583 34.668C35.113 34.668 39.2917 37.0558 39.2917 40.0013Z" fill="url(#paint1_linear_1_163)" />
                <path d="M45.9565 40.0006C45.9565 42.2097 43.2425 44.0006 39.9288 44.0006C40.905 42.9335 41.5763 41.594 41.5763 40.0025C41.5763 38.4091 40.9035 37.0684 39.9254 36.0007C43.2391 36.0007 45.9565 37.7915 45.9565 40.0006Z" fill="url(#paint2_linear_1_163)" />
                <path d="M41.9565 25.3343C41.9565 27.5434 40.1657 29.3343 37.9565 29.3343C37.4748 29.3343 37.013 29.2492 36.5853 29.0931C37.2161 27.9836 37.5763 26.7003 37.5763 25.3328C37.5763 23.9664 37.2166 22.6839 36.5867 21.575C37.014 21.4193 37.4753 21.3343 37.9565 21.3343C40.1657 21.3343 41.9565 23.1252 41.9565 25.3343Z" fill="url(#paint3_linear_1_163)" />
              </g>
              <g filter="url(#filter1_f_1_163)">
                <path d="M35.2917 25.3333C35.2917 28.2789 32.9039 30.6667 29.9583 30.6667C27.0128 30.6667 24.625 28.2789 24.625 25.3333C24.625 22.3878 27.0128 20 29.9583 20C32.9039 20 35.2917 22.3878 35.2917 25.3333Z" fill="url(#paint4_linear_1_163)" />
                <path d="M39.2917 40.0013C39.2917 42.9468 35.113 45.3346 29.9583 45.3346C24.8037 45.3346 20.625 42.9468 20.625 40.0013C20.625 37.0558 24.8037 34.668 29.9583 34.668C35.113 34.668 39.2917 37.0558 39.2917 40.0013Z" fill="url(#paint5_linear_1_163)" />
                <path d="M45.9565 40.0006C45.9565 42.2097 43.2425 44.0006 39.9288 44.0006C40.905 42.9335 41.5763 41.594 41.5763 40.0025C41.5763 38.4091 40.9035 37.0684 39.9254 36.0007C43.2391 36.0007 45.9565 37.7915 45.9565 40.0006Z" fill="url(#paint6_linear_1_163)" />
                <path d="M41.9565 25.3343C41.9565 27.5434 40.1657 29.3343 37.9565 29.3343C37.4748 29.3343 37.013 29.2492 36.5853 29.0931C37.2161 27.9836 37.5763 26.7003 37.5763 25.3328C37.5763 23.9664 37.2166 22.6839 36.5867 21.575C37.014 21.4193 37.4753 21.3343 37.9565 21.3343C40.1657 21.3343 41.9565 23.1252 41.9565 25.3343Z" fill="url(#paint7_linear_1_163)" />
              </g>
              <path d="M35.2917 25.3333C35.2917 28.2789 32.9039 30.6667 29.9583 30.6667C27.0128 30.6667 24.625 28.2789 24.625 25.3333C24.625 22.3878 27.0128 20 29.9583 20C32.9039 20 35.2917 22.3878 35.2917 25.3333Z" fill="url(#paint8_linear_1_163)" />
              <path d="M39.2917 40.0013C39.2917 42.9468 35.113 45.3346 29.9583 45.3346C24.8037 45.3346 20.625 42.9468 20.625 40.0013C20.625 37.0558 24.8037 34.668 29.9583 34.668C35.113 34.668 39.2917 37.0558 39.2917 40.0013Z" fill="url(#paint9_linear_1_163)" />
              <path d="M45.9565 40.0006C45.9565 42.2097 43.2425 44.0006 39.9288 44.0006C40.905 42.9335 41.5763 41.594 41.5763 40.0025C41.5763 38.4091 40.9035 37.0684 39.9254 36.0007C43.2391 36.0007 45.9565 37.7915 45.9565 40.0006Z" fill="url(#paint10_linear_1_163)" />
              <path d="M41.9565 25.3343C41.9565 27.5434 40.1657 29.3343 37.9565 29.3343C37.4748 29.3343 37.013 29.2492 36.5853 29.0931C37.2161 27.9836 37.5763 26.7003 37.5763 25.3328C37.5763 23.9664 37.2166 22.6839 36.5867 21.575C37.014 21.4193 37.4753 21.3343 37.9565 21.3343C40.1657 21.3343 41.9565 23.1252 41.9565 25.3343Z" fill="url(#paint11_linear_1_163)" />
              <defs>
                <filter id="filter0_f_1_163" x="0.625" y="0" width="65.3315" height="65.3347" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_1_163" />
                </filter>
                <filter id="filter1_f_1_163" x="16.625" y="16" width="33.3315" height="33.3347" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1_163" />
                </filter>
                <linearGradient id="paint0_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint3_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint4_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint5_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint6_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint7_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint8_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint9_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint10_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint11_linear_1_163" x1="33.2908" y1="20" x2="33.2908" y2="45.3346" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
              </defs>
            </svg>
            </div>
            ) : (
              <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2917 6.33333C16.2917 9.27885 13.9039 11.6667 10.9583 11.6667C8.01281 11.6667 5.625 9.27885 5.625 6.33333C5.625 3.38781 8.01281 1 10.9583 1C13.9039 1 16.2917 3.38781 16.2917 6.33333Z" fill="white" fillOpacity="0.3"/>
              <path d="M20.2917 21.0013C20.2917 23.9468 16.113 26.3346 10.9583 26.3346C5.80368 26.3346 1.625 23.9468 1.625 21.0013C1.625 18.0558 5.80368 15.668 10.9583 15.668C16.113 15.668 20.2917 18.0558 20.2917 21.0013Z" fill="white" fillOpacity="0.3"/>
              <path d="M26.9565 21.0006C26.9565 23.2097 24.2425 25.0006 20.9288 25.0006C21.905 23.9335 22.5763 22.594 22.5763 21.0025C22.5763 19.4091 21.9035 18.0684 20.9254 17.0007C24.2391 17.0007 26.9565 18.7915 26.9565 21.0006Z" fill="white" fillOpacity="0.3"/>
              <path d="M22.9565 6.33431C22.9565 8.54345 21.1657 10.3343 18.9565 10.3343C18.4748 10.3343 18.013 10.2492 17.5853 10.0931C18.2161 8.98363 18.5763 7.7003 18.5763 6.33282C18.5763 4.96636 18.2166 3.68392 17.5867 2.57502C18.014 2.41927 18.4753 2.33431 18.9565 2.33431C21.1657 2.33431 22.9565 4.12517 22.9565 6.33431Z" fill="white" fillOpacity="0.3"/>
              </svg>
            )}
            Friends
          </>
        )}
      </NavLink>
      <NavLink to="/wallet" className={({ isActive }) => (isActive ? 'active' : '')}>
        {({ isActive }) => (
          <>
            {isActive ? (
              <div className="svg-container">
              <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_f_122_8127)">
                <path fillRule="evenodd" clipRule="evenodd" d="M41.7123 29.504C41.6486 29.5 41.5792 29.5 41.5073 29.5L41.4893 29.5001H38.6685C36.3435 29.5001 34.354 31.3446 34.354 33.7501C34.354 36.1555 36.3435 38 38.6685 38H41.4893L41.5073 38.0001C41.5792 38.0001 41.6486 38.0001 41.7123 37.9961C42.6568 37.936 43.492 37.1911 43.5622 36.1507C43.5668 36.0825 43.5668 36.0089 43.5667 35.9408L43.5667 35.9223V31.5778L43.5667 31.5593C43.5668 31.4912 43.5668 31.4176 43.5622 31.3494C43.492 30.309 42.6568 29.5642 41.7123 29.504ZM38.4184 34.8834C39.017 34.8834 39.5023 34.376 39.5023 33.75C39.5023 33.1241 39.017 32.6167 38.4184 32.6167C37.8198 32.6167 37.3346 33.1241 37.3346 33.75C37.3346 34.376 37.8198 34.8834 38.4184 34.8834Z" fill="url(#paint0_linear_122_8127)" fillOpacity="0.6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M41.5066 39.7001C41.6742 39.6957 41.8011 39.8522 41.7556 40.0149C41.5299 40.8223 41.1716 41.5105 40.5967 42.09C39.7551 42.9381 38.688 43.3145 37.3697 43.4932C36.0886 43.6667 34.4517 43.6667 32.3852 43.6667H30.0093C27.9428 43.6667 26.3059 43.6667 25.0249 43.4932C23.7065 43.3145 22.6394 42.9381 21.7978 42.09C20.9563 41.2418 20.5829 40.1663 20.4056 38.8376C20.2334 37.5465 20.2334 35.8968 20.2334 33.814V33.6861C20.2334 31.6033 20.2334 29.9536 20.4056 28.6625C20.5829 27.3337 20.9563 26.2583 21.7978 25.4101C22.6394 24.562 23.7065 24.1856 25.0249 24.0069C26.3059 23.8333 27.9428 23.8334 30.0093 23.8334L32.3852 23.8334C34.4518 23.8334 36.0886 23.8333 37.3696 24.0069C38.688 24.1856 39.7551 24.562 40.5967 25.4101C41.1716 25.9896 41.5299 26.6778 41.7556 27.4852C41.8011 27.6479 41.6742 27.8044 41.5065 27.8L38.6684 27.8001C35.4786 27.8001 32.6671 30.3397 32.6671 33.7501C32.6671 37.1604 35.4786 39.7001 38.6684 39.7001L41.5066 39.7001ZM24.4503 28.3667C23.9845 28.3667 23.6069 28.7473 23.6069 29.2167C23.6069 29.6862 23.9845 30.0667 24.4503 30.0667H28.9483C29.414 30.0667 29.7916 29.6862 29.7916 29.2167C29.7916 28.7473 29.414 28.3667 28.9483 28.3667H24.4503Z" fill="url(#paint1_linear_122_8127)" fillOpacity="0.6" />
                <path d="M26.9729 22.6952L29.2581 21.0115C30.4854 20.1073 32.1481 20.1073 33.3753 21.0115L35.6726 22.704C34.7121 22.6667 33.6394 22.6667 32.4634 22.6667H29.9311C28.8565 22.6667 27.8682 22.6667 26.9729 22.6952Z" fill="url(#paint2_linear_122_8127)" fillOpacity="0.6" />
              </g>
              <g filter="url(#filter1_f_122_8127)">
                <path fillRule="evenodd" clipRule="evenodd" d="M41.7123 29.504C41.6486 29.5 41.5792 29.5 41.5073 29.5L41.4893 29.5001H38.6685C36.3435 29.5001 34.354 31.3446 34.354 33.7501C34.354 36.1555 36.3435 38 38.6685 38H41.4893L41.5073 38.0001C41.5792 38.0001 41.6486 38.0001 41.7123 37.9961C42.6568 37.936 43.492 37.1911 43.5622 36.1507C43.5668 36.0825 43.5668 36.0089 43.5667 35.9408L43.5667 35.9223V31.5778L43.5667 31.5593C43.5668 31.4912 43.5668 31.4176 43.5622 31.3494C43.492 30.309 42.6568 29.5642 41.7123 29.504ZM38.4184 34.8834C39.017 34.8834 39.5023 34.376 39.5023 33.75C39.5023 33.1241 39.017 32.6167 38.4184 32.6167C37.8198 32.6167 37.3346 33.1241 37.3346 33.75C37.3346 34.376 37.8198 34.8834 38.4184 34.8834Z" fill="url(#paint3_linear_122_8127)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M41.5066 39.7001C41.6742 39.6957 41.8011 39.8522 41.7556 40.0149C41.5299 40.8223 41.1716 41.5105 40.5967 42.09C39.7551 42.9381 38.688 43.3145 37.3697 43.4932C36.0886 43.6667 34.4517 43.6667 32.3852 43.6667H30.0093C27.9428 43.6667 26.3059 43.6667 25.0249 43.4932C23.7065 43.3145 22.6394 42.9381 21.7978 42.09C20.9563 41.2418 20.5829 40.1663 20.4056 38.8376C20.2334 37.5465 20.2334 35.8968 20.2334 33.814V33.6861C20.2334 31.6033 20.2334 29.9536 20.4056 28.6625C20.5829 27.3337 20.9563 26.2583 21.7978 25.4101C22.6394 24.562 23.7065 24.1856 25.0249 24.0069C26.3059 23.8333 27.9428 23.8334 30.0093 23.8334L32.3852 23.8334C34.4518 23.8334 36.0886 23.8333 37.3696 24.0069C38.688 24.1856 39.7551 24.562 40.5967 25.4101C41.1716 25.9896 41.5299 26.6778 41.7556 27.4852C41.8011 27.6479 41.6742 27.8044 41.5065 27.8L38.6684 27.8001C35.4786 27.8001 32.6671 30.3397 32.6671 33.7501C32.6671 37.1604 35.4786 39.7001 38.6684 39.7001L41.5066 39.7001ZM24.4503 28.3667C23.9845 28.3667 23.6069 28.7473 23.6069 29.2167C23.6069 29.6862 23.9845 30.0667 24.4503 30.0667H28.9483C29.414 30.0667 29.7916 29.6862 29.7916 29.2167C29.7916 28.7473 29.414 28.3667 28.9483 28.3667H24.4503Z" fill="url(#paint4_linear_122_8127)" />
                <path d="M26.9729 22.6952L29.2581 21.0115C30.4854 20.1073 32.1481 20.1073 33.3753 21.0115L35.6726 22.704C34.7121 22.6667 33.6394 22.6667 32.4634 22.6667H29.9311C28.8565 22.6667 27.8682 22.6667 26.9729 22.6952Z" fill="url(#paint5_linear_122_8127)" />
              </g>
              <path fillRule="evenodd" clipRule="evenodd" d="M41.7123 29.504C41.6486 29.5 41.5792 29.5 41.5073 29.5L41.4893 29.5001H38.6685C36.3435 29.5001 34.354 31.3446 34.354 33.7501C34.354 36.1555 36.3435 38 38.6685 38H41.4893L41.5073 38.0001C41.5792 38.0001 41.6486 38.0001 41.7123 37.9961C42.6568 37.936 43.492 37.1911 43.5622 36.1507C43.5668 36.0825 43.5668 36.0089 43.5667 35.9408L43.5667 35.9223V31.5778L43.5667 31.5593C43.5668 31.4912 43.5668 31.4176 43.5622 31.3494C43.492 30.309 42.6568 29.5642 41.7123 29.504ZM38.4184 34.8834C39.017 34.8834 39.5023 34.376 39.5023 33.75C39.5023 33.1241 39.017 32.6167 38.4184 32.6167C37.8198 32.6167 37.3346 33.1241 37.3346 33.75C37.3346 34.376 37.8198 34.8834 38.4184 34.8834Z" fill="url(#paint6_linear_122_8127)" />
              <path fillRule="evenodd" clipRule="evenodd" d="M41.5066 39.7001C41.6742 39.6957 41.8011 39.8522 41.7556 40.0149C41.5299 40.8223 41.1716 41.5105 40.5967 42.09C39.7551 42.9381 38.688 43.3145 37.3697 43.4932C36.0886 43.6667 34.4517 43.6667 32.3852 43.6667H30.0093C27.9428 43.6667 26.3059 43.6667 25.0249 43.4932C23.7065 43.3145 22.6394 42.9381 21.7978 42.09C20.9563 41.2418 20.5829 40.1663 20.4056 38.8376C20.2334 37.5465 20.2334 35.8968 20.2334 33.814V33.6861C20.2334 31.6033 20.2334 29.9536 20.4056 28.6625C20.5829 27.3337 20.9563 26.2583 21.7978 25.4101C22.6394 24.562 23.7065 24.1856 25.0249 24.0069C26.3059 23.8333 27.9428 23.8334 30.0093 23.8334L32.3852 23.8334C34.4518 23.8334 36.0886 23.8333 37.3696 24.0069C38.688 24.1856 39.7551 24.562 40.5967 25.4101C41.1716 25.9896 41.5299 26.6778 41.7556 27.4852C41.8011 27.6479 41.6742 27.8044 41.5065 27.8L38.6684 27.8001C35.4786 27.8001 32.6671 30.3397 32.6671 33.7501C32.6671 37.1604 35.4786 39.7001 38.6684 39.7001L41.5066 39.7001ZM24.4503 28.3667C23.9845 28.3667 23.6069 28.7473 23.6069 29.2167C23.6069 29.6862 23.9845 30.0667 24.4503 30.0667H28.9483C29.414 30.0667 29.7916 29.6862 29.7916 29.2167C29.7916 28.7473 29.414 28.3667 28.9483 28.3667H24.4503Z" fill="url(#paint7_linear_122_8127)" />
              <path d="M26.9729 22.6952L29.2581 21.0115C30.4854 20.1073 32.1481 20.1073 33.3753 21.0115L35.6726 22.704C34.7121 22.6667 33.6394 22.6667 32.4634 22.6667H29.9311C28.8565 22.6667 27.8682 22.6667 26.9729 22.6952Z" fill="url(#paint8_linear_122_8127)" />
              <defs>
                <filter id="filter0_f_122_8127" x="0.233398" y="0.333374" width="63.3335" height="63.3334" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_122_8127" />
                </filter>
                <filter id="filter1_f_122_8127" x="16.2334" y="16.3334" width="31.3335" height="31.3334" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_122_8127" />
                </filter>
                <linearGradient id="paint0_linear_122_8127" x1="31.9001" y1="20.3334" x2="31.9001" y2="43.6667" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint1_linear_122_8127" x1="31.9001" y1="20.3334" x2="31.9001" y2="43.6667" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint2_linear_122_8127" x1="31.9001" y1="20.3334" x2="31.9001" y2="43.6667" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint3_linear_122_8127" x1="31.9001" y1="20.3334" x2="31.9001" y2="43.6667" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint4_linear_122_8127" x1="31.9001" y1="20.3334" x2="31.9001" y2="43.6667" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint5_linear_122_8127" x1="31.9001" y1="20.3334" x2="31.9001" y2="43.6667" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint6_linear_122_8127" x1="31.9001" y1="20.3334" x2="31.9001" y2="43.6667" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint7_linear_122_8127" x1="31.9001" y1="20.3334" x2="31.9001" y2="43.6667" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
                <linearGradient id="paint8_linear_122_8127" x1="31.9001" y1="20.3334" x2="31.9001" y2="43.6667" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#2FB8FF" />
                  <stop offset="1" stopColor="#9EECD9" />
                </linearGradient>
              </defs>
            </svg>
            </div>
            ) : (
              <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M23.9372 11.504C23.8735 11.5 23.8041 11.5 23.7322 11.5L23.7142 11.5H20.8935C18.5684 11.5 16.5789 13.3446 16.5789 15.75C16.5789 18.1555 18.5684 20 20.8935 20H23.7142L23.7322 20C23.8041 20.0001 23.8735 20.0001 23.9372 19.996C24.8817 19.9359 25.7169 19.191 25.7871 18.1507C25.7917 18.0824 25.7917 18.0089 25.7916 17.9407L25.7916 17.9222V13.5778L25.7916 13.5593C25.7917 13.4911 25.7917 13.4176 25.7871 13.3494C25.7169 12.309 24.8817 11.5641 23.9372 11.504ZM20.6433 16.8833C21.2419 16.8833 21.7272 16.3759 21.7272 15.75C21.7272 15.1241 21.2419 14.6167 20.6433 14.6167C20.0447 14.6167 19.5595 15.1241 19.5595 15.75C19.5595 16.3759 20.0447 16.8833 20.6433 16.8833Z" fill="white" fillOpacity="0.3"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.7315 21.7C23.8991 21.6956 24.026 21.8522 23.9805 22.0148C23.7548 22.8223 23.3965 23.5105 22.8216 24.0899C21.98 24.9381 20.913 25.3145 19.5946 25.4931C18.3135 25.6667 16.6767 25.6667 14.6101 25.6667H12.2342C10.1677 25.6667 8.53082 25.6667 7.24977 25.4931C5.93139 25.3145 4.8643 24.9381 4.02276 24.0899C3.18123 23.2418 2.80777 22.1663 2.63051 20.8376C2.45828 19.5465 2.4583 17.8967 2.45831 15.8139V15.6861C2.4583 13.6033 2.45828 11.9536 2.63051 10.6625C2.80777 9.33371 3.18123 8.25823 4.02276 7.41009C4.8643 6.56194 5.93139 6.18554 7.24977 6.0069C8.53082 5.83331 10.1677 5.83333 12.2342 5.83334L14.6101 5.83334C16.6767 5.83333 18.3135 5.83331 19.5946 6.0069C20.913 6.18554 21.98 6.56194 22.8216 7.41009C23.3965 7.98954 23.7548 8.67772 23.9805 9.48515C24.026 9.64787 23.8991 9.8044 23.7314 9.80002L20.8934 9.80002C17.7036 9.80002 14.892 12.3397 14.892 15.75C14.892 19.1603 17.7036 21.7 20.8934 21.7L23.7315 21.7ZM6.67518 10.3667C6.2094 10.3667 5.83181 10.7472 5.83181 11.2167C5.83181 11.6861 6.2094 12.0667 6.67518 12.0667H11.1732C11.639 12.0667 12.0165 11.6861 12.0165 11.2167C12.0165 10.7472 11.639 10.3667 11.1732 10.3667H6.67518Z" fill="white" fillOpacity="0.3"/>
<path d="M9.19777 4.69513L11.483 3.01148C12.7103 2.1073 14.373 2.1073 15.6002 3.01148L17.8975 4.70398C16.937 4.66662 15.8643 4.66665 14.6883 4.66668H12.156C11.0814 4.66665 10.0931 4.66663 9.19777 4.69513Z" fill="white" fillOpacity="0.3"/>
</svg>

            )}
            Wallet
          </>
        )}
      </NavLink>
    </div>
  );
};

export default Menu;
